import {
  Collapse,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { UsageAndCostAlert } from "@react-ms-apps/common/types";
import { classNames } from "@react-ms-apps/common/utils/styles";
import { useMemo, useState } from "react";
import { getAlertMap } from "./utils";

export default function UsageCostAlertTable({
  usageCostAlertData,
  selectedDate,
}: {
  usageCostAlertData: UsageAndCostAlert[];
  selectedDate: Date;
}) {
  const [showTable, setShowTable] = useState<boolean>(true);

  const alertsCount = useMemo(() => {
    return usageCostAlertData.filter((item) => item.action_needed).length;
  }, [usageCostAlertData]);

  const alertMap = useMemo(() => {
    return getAlertMap(selectedDate);
  }, [selectedDate]);

  const filteredUsageCostAlertData = useMemo(() => {
    return usageCostAlertData.filter(
      (item) => alertMap[item.name] && item.threshold !== null && item.value > 0
    );
  }, [alertMap, usageCostAlertData]);

  const getDisplayThreshold = (item: UsageAndCostAlert) => {
    const alert = alertMap[item.name];
    if (alert && alert.displayThreshold && item.threshold) {
      return alert.displayThreshold(item.threshold);
    }

    if (item.threshold) {
      return alertMap[item.name].threshold_tpl.replace(
        "REPL",
        item.threshold.toString()
      );
    }

    return "";
  };

  const getDisplayValue = (item: UsageAndCostAlert) => {
    const alert = alertMap[item.name];
    if (alert && alert.displayValue) {
      return alert.displayValue(item.value);
    }

    return item.value;
  };

  const handleRowClick = (item: UsageAndCostAlert) => {
    const alertMapItem = alertMap[item.name];

    if (!alertMapItem) return;

    const url = alertMapItem.getUrl(item);
    window.location.href = url;
  };

  return (
    <Paper className="mt-4">
      <div
        onClick={() => setShowTable(!showTable)}
        className={classNames(
          "cursor-pointer p-4 py-2 bg-red-600",
          showTable ? "rounded-tl rounded-tr" : "rounded"
        )}
      >
        <Typography variant="h3" textAlign="center" color="white">
          {alertsCount} Alert{alertsCount === 1 ? "" : "s"} Found
        </Typography>
      </div>

      <Collapse in={showTable}>
        <div className="p-4">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Threshold</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsageCostAlertData.map((item) => (
                <TableRow
                  component={Link}
                  key={item.name}
                  hover
                  onClick={() => handleRowClick(item)}
                  className={classNames(
                    "cursor-pointer",
                    item.action_needed && "!bg-red-100"
                  )}
                  href={alertMap[item.name].getUrl(item)}
                >
                  <TableCell
                    className={classNames(
                      item.action_needed && "!text-red-800"
                    )}
                  >
                    {alertMap[item.name].display}
                  </TableCell>

                  {item.threshold !== null && (
                    <TableCell
                      className={classNames(
                        item.action_needed && "!text-red-800"
                      )}
                    >
                      {getDisplayThreshold(item)}
                    </TableCell>
                  )}

                  <TableCell
                    className={classNames(
                      item.action_needed && "!text-red-800"
                    )}
                  >
                    {getDisplayValue(item)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Collapse>
    </Paper>
  );
}
