import {
  Button,
  CssBaseline,
  Modal,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Header, fetchForgotPasswordRemoved } from "@react-ms-apps/common";
import ROUTES from "@react-ms-apps/common/constants/routes";
import theme from "@react-ms-apps/common/mui/theme";
import {
  AuthProvider,
  FooterProvider,
  NavigationProvider,
  OrgInfoProvider,
  useAuth,
  useFooter,
} from "@react-ms-apps/common/providers";
import { getClientDB } from "@react-ms-apps/common/utils";
import Logout from "@react-ms-apps/common/utils/logout";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DashboardApp from "./App/dashboard";
import UtilitiesApp from "./App/utilities";
import DIYStatementUpload from "./Components/DIYStatementUpload";
import ForgotPassword from "./Components/ForgotPassword";
import Login from "./Components/Login";
import LoginRedirect from "./Components/LoginRedirect";
import NotFoundPage from "./Components/NotFoundPage";
import ReportsPage from "./Components/Reports";
import ResetPassword from "./Components/ResetPassword";
import SentPasswordReset from "./Components/SentPasswordReset";
import "./Constants/mui-license";
import {
  LocationContextProvider,
  useLocationContext,
} from "./Providers/LocationContext";
import { PageTitleProvider } from "./Providers/PageTitleProvider";

function App() {
  const [forgotPasswordRemoved, setForgotPasswordRemoved] = useState(false);
  const [authenticationLoaded, setAuthenticationLoaded] = useState(false);

  const { isLoginRoute } = useLocationContext();
  const { checkAuth, isAuthenticated, hasCheckedCookieBaseline } = useAuth();
  const { Footer } = useFooter();

  const fetchAssets = useCallback(async () => {
    try {
      // forgot password removed
      const forgotPasswordRemoved = await fetchForgotPasswordRemoved();
      setForgotPasswordRemoved(forgotPasswordRemoved);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  const getIsAuthenticated = useCallback(async () => {
    setAuthenticationLoaded(false);

    try {
      await checkAuth();
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setAuthenticationLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchAssets();
    getIsAuthenticated();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAssets]);

  return (
    <NavigationProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="h-full flex flex-col mx-4 border border-black border-solid mb-12 app-root">
          {hasCheckedCookieBaseline && (
            <Header showNavigation={isAuthenticated} />
          )}

          <Routes>
            <Route
              path={ROUTES.LOGIN.HOME}
              element={<Login allowForgotPassword={!forgotPasswordRemoved} />}
            />

            <Route
              path={ROUTES.BASE}
              element={<Navigate to={ROUTES.LOGIN.HOME} />}
            />

            <Route
              path={ROUTES.AUTHENTICATED_REDIRECT}
              element={<LoginRedirect />}
            />

            <Route
              path={`${ROUTES.LOGIN.HOME}/*`}
              element={<Navigate to={ROUTES.LOGIN.HOME} />}
            />

            {/* Do not allow route if forgotPasswordRemoved is true */}
            {!forgotPasswordRemoved && (
              <>
                <Route
                  path={ROUTES.LOGIN.FORGOT_PASSWORD}
                  element={<ForgotPassword />}
                />

                <Route
                  path={ROUTES.LOGIN.SENT_PASSWORD_RESET}
                  element={<SentPasswordReset />}
                />

                <Route
                  path={`${ROUTES.LOGIN.PASSWORD_RESET}`}
                  element={<ResetPassword />}
                />
              </>
            )}

            <Route
              path={`${ROUTES.UTILITY.ROOT}/*`}
              element={<UtilitiesApp />}
            />

            <Route
              path={`${ROUTES.DASHBOARD.ROOT}/*`}
              element={<DashboardApp />}
            />

            <Route path={`${ROUTES.REPORTS.ROOT}`} element={<ReportsPage />} />

            <Route
              path={`${ROUTES.DIY.STATEMENT_UPLOAD}`}
              element={<DIYStatementUpload />}
            />

            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <Footer />

        {authenticationLoaded && !isAuthenticated && !isLoginRoute && (
          <Modal open className="flex" disableAutoFocus>
            {/* show modal for unauthenticated user */}
            {/* instruct user that they will be redirected */}
            <div className="flex flex-1 flex-col items-center justify-center">
              <div className="flex flex-col justify-center bg-white mx-auto max-w-4xl rounded-xl p-8">
                <Typography variant="h3" align="center">
                  Not Authenticated
                </Typography>
                <div className="flex flex-1 flex-col justify-center text-center">
                  <div className="my-4 gap-y-2 flex flex-col">
                    <Typography align="center">
                      You are not authenticated to use this application.
                    </Typography>
                    <Typography align="center">
                      You will be redirected to the login page.
                    </Typography>
                  </div>

                  <div className="flex flex-col items-center justify-center mt-8">
                    <Button
                      size="large"
                      fullWidth
                      variant="contained"
                      onClick={Logout}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition={Slide}
        />
      </ThemeProvider>
    </NavigationProvider>
  );
}

export default function AppWrapper() {
  const client = useMemo(() => getClientDB(), []);

  return (
    <BrowserRouter basename={`${client}`}>
      <AuthProvider>
        <OrgInfoProvider>
          <FooterProvider>
            <LocationContextProvider>
              <PageTitleProvider>
                <App />
              </PageTitleProvider>
            </LocationContextProvider>
          </FooterProvider>
        </OrgInfoProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
