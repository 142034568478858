import { CircularProgress, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React, { useCallback, useEffect, useState } from "react";
import { UtilityLinkDTO, fetchUtilitiesLinks } from "../../api";
import { classNames } from "../../utils/styles";
import Container from "../Container";

export default function PageContainer({
  children,
  header,
  headerId,
  description,
  descriptionId,
  className,
  utilityName,
  style,
}: {
  children?: React.ReactNode;
  header?: string;
  headerId?: string;
  description?: string | React.ReactNode;
  descriptionId?: string;
  className?: string;
  utilityName?: string;
  style?: React.CSSProperties;
}) {
  const [pageTitle, setPageTitle] = useState("");
  const [loadingTitle, setLoadingTitle] = useState(true);

  const getPageTitle = useCallback(async () => {
    setLoadingTitle(true);

    try {
      const utilityLinks = await fetchUtilitiesLinks();

      let utilityLink: UtilityLinkDTO | null = null;

      for (let i = 0; i < utilityLinks.length; i++) {
        const category = utilityLinks[i];

        for (let j = 0; j < category.links.length; j++) {
          const link = category.links[j];
          if (link.utility_name === utilityName) {
            utilityLink = link;
            break;
          }
        }
      }

      if (utilityLink) {
        setPageTitle(utilityLink.custom_utility_name || utilityName);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingTitle(false);
    }
  }, [utilityName]);

  useEffect(() => {
    if (utilityName) {
      getPageTitle();
    } else {
      setLoadingTitle(false);
    }
  }, [getPageTitle, utilityName]);

  const hasHeader = !!header || !!pageTitle;

  if (loadingTitle) {
    return (
      <Container
        style={style}
        className={classNames("!w-full flex flex-1 flex-col", className)}
      >
        <div className="flex flex-1 justify-center items-center">
          <CircularProgress />
        </div>
      </Container>
    );
  }

  return (
    <Container
      style={style}
      className={classNames("!w-full flex flex-1 flex-col", className)}
    >
      {hasHeader && (
        <div className="my-4">
          <Typography id={headerId || ""} variant="h1">
            {pageTitle || header}
          </Typography>
        </div>
      )}

      {!!description && (
        <div className="my-4 mb-8" id={descriptionId}>
          {typeof description === "string" ? (
            <Typography variant="body1">{description}</Typography>
          ) : (
            description
          )}
        </div>
      )}

      {children}
    </Container>
  );
}
