import { fetchBrandingPageTitlePrefix } from "@react-ms-apps/common";
import * as Sentry from "@sentry/react";
import React, { createContext, useContext, useEffect, useState } from "react";

interface PageTitleContextProps {
  title: string;
  setTitle: (title: string) => void;
}

const PageTitleContext = createContext<PageTitleContextProps | undefined>(
  undefined
);

export const PageTitleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [brandingPageTitlePrefix, setBrandingPageTitlePrefix] = useState<
    null | string
  >(null);

  const setTitle = (title: string) => {
    if (!brandingPageTitlePrefix) {
      return;
    }

    document.title = `${brandingPageTitlePrefix} - ${title}`;
  };

  const getBrandingPageTitlePrefix = async () => {
    try {
      const brandingPageTitlePrefix = await fetchBrandingPageTitlePrefix();
      setBrandingPageTitlePrefix(brandingPageTitlePrefix);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  // fetch data
  useEffect(() => {
    getBrandingPageTitlePrefix();
  }, []);

  // updates the document title with a loading animation
  useEffect(() => {
    let i = 1;
    const animationInterval = setInterval(() => {
      if (!document.title.includes("Loading") && document.title !== "") {
        clearInterval(animationInterval);
        return;
      }

      document.title = `Loading${i === 0 ? "" : ".".repeat(i)}`;
      i++;

      if (i > 3) {
        i = 0;
      }
    }, 300);
  }, []);

  // updates the document title with the branding page title prefix
  useEffect(() => {
    // do not update the document title if the branding page title prefix is already set
    if (
      brandingPageTitlePrefix &&
      !document.title.includes(brandingPageTitlePrefix)
    ) {
      document.title = brandingPageTitlePrefix;
    }
  }, [brandingPageTitlePrefix]);

  return (
    <PageTitleContext.Provider value={{ title: document.title, setTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};

export const usePageTitle = (): PageTitleContextProps => {
  const context = useContext(PageTitleContext);

  if (!context) {
    throw new Error("usePageTitle must be used within a PageTitleProvider");
  }

  return context;
};
