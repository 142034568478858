import axios from "axios";
import { UploadProcessTypes } from "../constants/uploadProcessTypes";
import { buildApiUrl } from "../utils";

export const uploadCsvToProcessor = async (
  file: File,
  processType: UploadProcessTypes,
  allowBlankValues?: boolean
) => {
  var formdata = new FormData();
  formdata.append("files", file, file.name);

  let apiRoute = "";
  switch (processType) {
    case UploadProcessTypes.BatchStatementEditor:
      apiRoute = "api/ban_summaries";
      break;

    case UploadProcessTypes.AssetRefresh:
      apiRoute = "api/asset";
      break;

    case UploadProcessTypes.CostCenterRefreshByDevice:
      apiRoute = "api/nc/employee/update/by_device";
      break;

    case UploadProcessTypes.CostCenterRefreshByEmployeeID:
      apiRoute = "api/nc/employee/update/by_employee_num";
      break;

    case UploadProcessTypes.UserCreation:
      apiRoute = "api/nc/employee/create";
      break;

    case UploadProcessTypes.ManagerRefreshByDeviceManager:
      apiRoute = "api/nc/manager/update/by_name";
      break;

    case UploadProcessTypes.ManagerRefreshByEmployeeIDManager:
      apiRoute = "api/nc/manager/update/by_id";
      break;

    case UploadProcessTypes.PlanOverrideUtility:
      apiRoute = "api/plan_override";
      break;

    default:
      break;
  }

  if (apiRoute === "") {
    throw new Error("Invalid process type");
  }

  // allows blank values to be updated
  let apiURL = buildApiUrl(apiRoute);
  if (allowBlankValues) {
    apiURL += "?update_blank=1";
  }

  const response = await axios.post<string | { d: null }>(apiURL, formdata);
  return response.data;
};
