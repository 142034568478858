import { Close } from "@mui/icons-material";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  FileUploadStatus,
  UploadStatement,
  fetchDIYPurgable,
  fetchPDFUploadStatuses,
  fetchPDFUploads,
  purgePDFUploads,
  submitPDFUploads,
} from "@react-ms-apps/common";
import { CarriersProvider, useCarriers } from "@react-ms-apps/common/providers";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import InfoDialog from "./InfoDialog";
import UploadFileRow from "./UploadFileRow";
import UploadedPDFRow from "./UploadedPDFRow";

const TableHeadCell = ({
  children,
  align = "center",
}: {
  children?: React.ReactNode;
  align?: "left" | "right" | "center";
}) => {
  return (
    <TableCell
      align={align}
      className="!py-1 px-8 text-sm text-center !text-white"
    >
      {children}
    </TableCell>
  );
};

enum PurgeStatus {
  None = "none",
  Initiated = "initiated",
  Pending = "pending",
}

function DIYStatementUpload() {
  const { carriersMap } = useCarriers();

  const [files, setFiles] = useState<File[]>([]);
  const [pdfUploads, setPdfUploads] = useState<UploadStatement[]>([]);
  const [showExistingUploadDialog, setShowExistingUploadDialog] =
    useState<boolean>(false);
  const [showSubmitSuccessDialog, setShowSubmitSuccessDialog] =
    useState<boolean>(false);
  const [uploadStatuses, setUploadStatuses] = useState<FileUploadStatus[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [purgeStatus, setPurgeStatus] = useState<PurgeStatus>(PurgeStatus.None);

  const isPurging = purgeStatus === "pending";

  const fileInputRef = useRef<HTMLInputElement>(null);

  const confirmedUploadStatusId = useMemo(() => {
    return uploadStatuses.find(
      (status) => status.file_upload_status === "Confirmed"
    )?.file_upload_status_id;
  }, [uploadStatuses]);

  const newUploadStatusId = useMemo(() => {
    return uploadStatuses.find((status) => status.file_upload_status === "New")
      ?.file_upload_status_id;
  }, [uploadStatuses]);

  const hasValidUploads = useMemo(() => {
    return (
      pdfUploads.filter(
        (pdf) => pdf.file_upload_status_id === newUploadStatusId
      ).length > 0
    );
  }, [newUploadStatusId, pdfUploads]);

  const uploadsSubmitted = useMemo(() => {
    return pdfUploads.some(
      (pdf) => pdf.file_upload_status_id !== newUploadStatusId
    );
  }, [newUploadStatusId, pdfUploads]);

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesList = e.target.files;
    if (!filesList) return;

    const files: File[] = [];
    // add files to state
    for (let i = 0; i < filesList.length; i++) {
      const file = filesList[i];
      files.push(file);
    }

    setFiles((prev) => [...prev, ...files]);

    // clear the input
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const getPDFUploads = useCallback(async () => {
    try {
      const uploadStatuses = await fetchPDFUploadStatuses();
      setUploadStatuses(uploadStatuses);

      const uploadedPDFs = await fetchPDFUploads();
      setPdfUploads(uploadedPDFs);

      const uploadsAreNew = uploadedPDFs.every(
        (pdf) => pdf.file_upload_status_id === newUploadStatusId
      );

      if (uploadedPDFs.length && uploadsAreNew) {
        setShowExistingUploadDialog(true);
      }
    } catch (error) {
      toast.error("Error fetching PDF uploads");
      Sentry.captureException(error);
    }
  }, [newUploadStatusId]);

  const handleSubmit = async () => {
    if (!confirmedUploadStatusId) {
      toast.error("Error submitting PDF uploads");
      return;
    }

    try {
      setSubmitting(true);
      const fileUploadIds = pdfUploads.map((pdf) => pdf.file_upload_id);
      const submitted = await submitPDFUploads(
        fileUploadIds,
        confirmedUploadStatusId
      );

      if (!submitted) {
        throw new Error("Error submitting PDF uploads");
      }

      // update the pdf uploads statuses
      setPdfUploads((prev) =>
        prev.map((pdf) =>
          fileUploadIds.includes(pdf.file_upload_id)
            ? { ...pdf, file_upload_status_id: confirmedUploadStatusId }
            : pdf
        )
      );
    } catch (error) {
      toast.error("Error submitting PDF uploads");
      Sentry.captureException(error);
    } finally {
      setSubmitting(false);
      setShowSubmitSuccessDialog(true);
    }
  };

  const handlePurgeData = async () => {
    try {
      setPurgeStatus(PurgeStatus.Initiated);

      const success = await purgePDFUploads();
      if (!success) {
        throw new Error("Error purging PDF uploads");
      }

      setPurgeStatus(PurgeStatus.Pending);
    } catch (error) {
      Sentry.captureException(error);
      toast.error("Error purging PDF uploads");
      setPurgeStatus(PurgeStatus.None);
    }
  };

  const getPurgeStatus = useCallback(async () => {
    try {
      const isPurging = await fetchDIYPurgable();
      if (isPurging) {
        setPurgeStatus(PurgeStatus.Pending);
      } else {
        // check
        await getPDFUploads();
        setPurgeStatus(PurgeStatus.None);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [getPDFUploads]);

  // periodcally check for purge status
  useEffect(() => {
    if (isPurging) {
      const interval = setInterval(() => {
        getPurgeStatus();
      }, 60 * 1000); // check every minute

      return () => clearInterval(interval);
    }
  }, [getPurgeStatus, isPurging]);

  // remove all files and uploads if currently purging
  useEffect(() => {
    if (isPurging) {
      setFiles([]);
      setPdfUploads([]);
    }
  }, [isPurging]);

  // fetch pdf uploads
  useEffect(() => {
    getPDFUploads();
    getPurgeStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPurging) {
    return (
      <div className="mx-6 mt-2 flex flex-col h-full">
        <Typography className="font-normal">statement upload</Typography>

        <div className="mx-auto flex items-center justify-center flex-col gap-4 h-full -mt-24">
          <div className="flex flex-col items-center justify-center mx-auto max-w-lg">
            <h2 className="text-center text-3xl leading-tight m-0">
              Purging Data
            </h2>
            <p className="text-center text-lg">
              Your data is being purged.
              <br />
              File uploads are not permitted until the purge has completed.
              <br />
              This process may take a few minutes.
            </p>
          </div>

          <CircularProgress size="3rem" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mx-6">
        <Typography variant="h5" className="font-norma">
          statement upload
        </Typography>
        <div className="flex flex-col gap-2 mt-2 mb-4">
          <Typography variant="body1">
            For each submitted analysis, up to three consecutive invoice months
            may be loaded. Invoices must either be in carrier-generated PDF
            formats (not zipped) or unmodified carrier-generated raw download
            files (zipped).
          </Typography>
          <Typography variant="body1">
            Click on the adjacent carrier link for directions on generating a
            compatible invoice from your carrier portal login:
            <a
              href="https://www.mobilsense.com/wp-content/uploads/2024/03/ATTPDFDownloadNew.pdf"
              target="_blank"
              className="text-primary-700 no-underline hover:text-primary-950 hover:underline ml-4 mx-2"
            >
              AT&T
            </a>
            <a
              href="https://www.mobilsense.com/wp-content/uploads/2024/03/TMOPDFDownloadInstructions.pdf"
              target="_blank"
              className="text-primary-700 no-underline hover:text-primary-950 hover:underline mx-2"
            >
              T-Mobile
            </a>
            <a
              href="https://www.mobilsense.com/wp-content/uploads/2024/03/VZWPDFDownloadNew.pdf"
              target="_blank"
              className="text-primary-700 no-underline hover:text-primary-950 hover:underline mx-2"
            >
              Verizon
            </a>
          </Typography>
        </div>

        <div>
          <Table>
            <TableHead className="bg-primary-800 text-white">
              <TableRow>
                <TableHeadCell align="left">File</TableHeadCell>
                <TableHeadCell>Carrier</TableHeadCell>
                <TableHeadCell>Account Number</TableHeadCell>
                <TableHeadCell>Invoice Date</TableHeadCell>
                <TableHeadCell>Invoice Amount</TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {files.map((file) => (
                <UploadFileRow
                  key={file.name}
                  file={file}
                  onDelete={() => {
                    setFiles((prev) => prev.filter((f) => f !== file));
                  }}
                  onUploadComplete={(uploadStatement) =>
                    setPdfUploads((prev) => [...prev, uploadStatement])
                  }
                />
              ))}

              {pdfUploads.map((pdfUpload) => (
                <UploadedPDFRow
                  key={pdfUpload.file_upload_id}
                  filename={pdfUpload.file_name}
                  fileUploadId={pdfUpload.file_upload_id}
                  carrier={carriersMap[pdfUpload.carrier_id]?.name || ""}
                  accountNumber={pdfUpload.foundation_account}
                  invoiceDate={pdfUpload.invoice_date}
                  invoiceTotal={pdfUpload.invoice_total.toString()}
                  onDelete={() => {
                    setPdfUploads((prev) =>
                      prev.filter(
                        (pdf) => pdf.file_upload_id !== pdfUpload.file_upload_id
                      )
                    );
                  }}
                  isSubmitted={uploadsSubmitted}
                />
              ))}
            </TableBody>
          </Table>
        </div>

        <div className="mt-4 flex flex-row justify-between items-center">
          <div className="flex flex-row items-center gap-4">
            <button
              // trigger file input
              onClick={() => document.getElementById("file-input")?.click()}
              className="bg-primary-800 hover:bg-primary-950 border-none text-white rounded-md text-sm tracking-wide cursor-pointer px-4 py-0 h-8"
            >
              Upload Statement
            </button>

            <input
              multiple
              type="file"
              className="hidden"
              id="file-input"
              name="file-input"
              accept=".pdf"
              onChange={handleFilesChange}
              ref={fileInputRef}
            />

            {uploadsSubmitted && (
              <button
                disabled={
                  pdfUploads.length === 0 ||
                  [PurgeStatus.Initiated, PurgeStatus.Pending].includes(
                    purgeStatus
                  )
                }
                className="bg-gray-800 hover:bg-gray-700 border-none text-white rounded-md text-sm tracking-wide cursor-pointer px-2 py-0 h-8 flex flex-row items-center gap-2"
                onClick={handlePurgeData}
              >
                <div className="flex flex-row items-center gap-2 rounded-full bg-red-600 text-white">
                  <Close fontSize="small" />
                </div>
                <div className="flex flex-row items-center justify-between px-8">
                  Purge My Data
                </div>
                <span />
              </button>
            )}
          </div>

          <div className="flex flex-row gap-4 items-center">
            {hasValidUploads && (
              <p className="text-red-600 font-medium">
                Your invoices are ready to be submitted
              </p>
            )}

            <button
              disabled={!hasValidUploads || submitting || uploadsSubmitted}
              className="bg-[#87b03f] hover:opacity-80 border-none text-white rounded-md tracking-wide font-medium text-sm cursor-pointer px-8 py-0 h-8 disabled:bg-[#5cb85c] disabled:border-[#4cae4c] disabled:cursor-not-allowed disabled:hover:bg-[#5cb85c] disabled:opacity-60"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {showExistingUploadDialog && (
        <InfoDialog
          title="Existing Uploads"
          message={
            <>
              You have existing PDF uploads. You can either delete them and
              upload new files or submit the existing uploads for processing.
              <br />
              <br />
              If you choose to delete the existing uploads, you will not be able
              to recover them.
            </>
          }
          onClose={() => setShowExistingUploadDialog(false)}
        />
      )}

      {showSubmitSuccessDialog && (
        <InfoDialog
          title="Submitted"
          message="The uploaded statements will now be processed and analyzed. This could take several hours. You will receive an email confirmation when processing is complete indicating you may log in to view the results."
          onClose={() => setShowSubmitSuccessDialog(false)}
        />
      )}
    </>
  );
}

export default function DIYStatementUploadWrapper() {
  return (
    <CarriersProvider>
      <DIYStatementUpload />
    </CarriersProvider>
  );
}
