import { TableCell, TableRow } from "@mui/material";
import { deletePDFUpload } from "@react-ms-apps/common";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useState } from "react";
import { TrashFill } from "react-bootstrap-icons";
import { toast } from "react-toastify";
import { trimFilename } from "./utils";

interface UploadedPDFRowProps {
  filename: string;
  fileUploadId: number;
  carrier: string;
  accountNumber: string;
  invoiceDate: string;
  invoiceTotal: string;
  onDelete: () => void;
  isSubmitted?: boolean;
}

export default function UploadedPDFRow({
  filename,
  fileUploadId,
  carrier,
  accountNumber,
  invoiceDate,
  invoiceTotal,
  onDelete,
  isSubmitted,
}: UploadedPDFRowProps) {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    try {
      setIsDeleting(true);
      await deletePDFUpload(fileUploadId);
      onDelete();
      toast.success("File removed");
    } catch (error) {
      toast.error("Error removing file");
      Sentry.captureException(error);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <TableRow
      className={classNames(
        "[&>td]:py-1 [&>td]:text-xs",
        isSubmitted && "bg-gray-300",
        isSubmitted && "[&>td]:text-gray-500"
      )}
    >
      <TableCell align="left">{trimFilename(filename)}</TableCell>
      <TableCell align="center">{carrier}</TableCell>
      <TableCell align="center">{accountNumber}</TableCell>
      <TableCell align="center">
        {moment(invoiceDate).format("MMM YYYY")}
      </TableCell>
      <TableCell align="center">
        {
          // get the currency
          Number(invoiceTotal).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })
        }
      </TableCell>

      <TableCell className="!flex !flex-row !justify-end">
        {!isSubmitted && (
          <button
            disabled={isDeleting}
            onClick={handleDelete}
            className="bg-primary-800 hover:bg-primary-900 border-none text-white rounded-sm flex items-center justify-end py-1 cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <TrashFill />
          </button>
        )}
      </TableCell>
    </TableRow>
  );
}
