import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
} from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";
import { UsageAndCost } from "@react-ms-apps/common/types/invoice-summary";
import React, { useEffect, useMemo, useState } from "react";
import { exportUsageAsCsv } from "./export-utility";
import {
  AggregateBy,
  InvoiceDataSource,
  Series,
  convertToSeries,
  invoiceDataSourceNamesMap,
} from "./utils";

interface InvoiceDataSourceOption {
  label: string;
  value: InvoiceDataSource;
}

const dataSourceOptions: InvoiceDataSourceOption[] = Object.values(
  InvoiceDataSource
).map((value) => ({
  label: invoiceDataSourceNamesMap[value],
  value,
}));

export default function InvoiceSummaryChart({
  usageCostData,
  selectedDate,
}: {
  usageCostData: UsageAndCost[];
  selectedDate: Date;
}) {
  const availableCarrierNames = useMemo(() => {
    const carrierNames = new Set<string>();

    usageCostData.forEach((item) => {
      carrierNames.add(item.carrier_name);
    });

    return Array.from(carrierNames);
  }, [usageCostData]);

  const availableDeviceTypes = useMemo(() => {
    const deviceTypes = new Set<string>();

    usageCostData.forEach((item) => {
      deviceTypes.add(item.formatted_device_type);
    });

    return Array.from(deviceTypes);
  }, [usageCostData]);

  const [selectedDataSource, setSelectedDataSource] =
    useState<InvoiceDataSource>(InvoiceDataSource.TotalCost);
  const [showTrending, setShowTrending] = useState(false);
  const [selectedCarriers, setSelectedCarriers] = useState<string[]>(
    availableCarrierNames
  );
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<string[]>([]);
  const [selectedAggregateBy, setSelectedAggregateBy] =
    useState<AggregateBy>("carrier_name");

  const seriesData = useMemo((): Series => {
    return convertToSeries(
      usageCostData,
      selectedDataSource,
      selectedDate,
      showTrending,
      {
        selectedCarriers,
        selectedDeviceTypes,
        aggregateBy: selectedAggregateBy,
      }
    );
  }, [
    selectedAggregateBy,
    selectedCarriers,
    selectedDataSource,
    selectedDate,
    selectedDeviceTypes,
    showTrending,
    usageCostData,
  ]);

  const toggleSelectedCarrier = (item: string) => {
    let updatedSelectedCarriers: string[] = [];

    if (selectedCarriers.includes(item)) {
      updatedSelectedCarriers = selectedCarriers.filter((c) => c !== item);
    } else {
      updatedSelectedCarriers = [...selectedCarriers, item];
    }

    setSelectedCarriers(updatedSelectedCarriers);
  };

  const toggleSelectedDeviceType = (item: string) => {
    let updatedSelectedDeviceTypes: string[] = [];

    if (selectedDeviceTypes.includes(item)) {
      updatedSelectedDeviceTypes = selectedDeviceTypes.filter(
        (d) => d !== item
      );
    } else {
      updatedSelectedDeviceTypes = [...selectedDeviceTypes, item];
    }

    setSelectedDeviceTypes(updatedSelectedDeviceTypes);
  };

  const handleExport = () => {
    exportUsageAsCsv(seriesData);
  };

  useEffect(() => {
    if (selectedAggregateBy === "carrier_name") {
      setSelectedCarriers(availableCarrierNames);
      setSelectedDeviceTypes([]);
    } else {
      setSelectedCarriers([]);
      setSelectedDeviceTypes(availableDeviceTypes);
    }
  }, [availableCarrierNames, availableDeviceTypes, selectedAggregateBy]);

  const renderChartCheckboxes = () => {
    if (selectedAggregateBy === "carrier_name") {
      return availableCarrierNames.map((item) => (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectedCarriers.includes(item)}
              onChange={() => toggleSelectedCarrier(item)}
              size="small"
            />
          }
          label={item}
        />
      ));
    }

    return availableDeviceTypes.map((item) => (
      <FormControlLabel
        control={
          <Checkbox
            checked={selectedDeviceTypes.includes(item)}
            onChange={() => toggleSelectedDeviceType(item)}
            size="small"
          />
        }
        label={item}
      />
    ));
  };

  const renderChart = () => {
    if (showTrending) {
      return (
        <LineChart
          xAxis={[
            {
              scaleType: "band",
              data: seriesData.xLabels,
              label: "Months",
            },
          ]}
          yAxis={[
            {
              scaleType: "linear",
              valueFormatter: (value) => {
                if (
                  [
                    InvoiceDataSource.TotalDataUsageGB,
                    InvoiceDataSource.TotalVoiceUsage,
                  ].includes(selectedDataSource)
                ) {
                  return value;
                }

                return `$${value}`;
              },
              // label: "Total Cost",
            },
          ]}
          series={seriesData.items}
          height={300}
        />
      );
    }

    return (
      <BarChart
        xAxis={[
          {
            scaleType: "band",
            data: seriesData.xLabels,
            label: "Months",
          },
        ]}
        yAxis={[
          {
            scaleType: "linear",
            // label: "Total Cost",
            valueFormatter: (value) => {
              if (
                [
                  InvoiceDataSource.TotalDataUsageGB,
                  InvoiceDataSource.TotalVoiceUsage,
                ].includes(selectedDataSource)
              ) {
                return value;
              }

              return `$${value}`;
            },
          },
        ]}
        series={seriesData.items}
        height={300}
      />
    );
  };

  return (
    <div className="w-1/2">
      <Paper>
        <div className="flex flex-col">
          <div className="flex flex-row border-primary-800 border-solid border py-2 px-4 rounded-md items-center">
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={selectedAggregateBy}
                onChange={(e) =>
                  setSelectedAggregateBy(e.target.value as AggregateBy)
                }
              >
                <FormControlLabel
                  value="carrier_name"
                  control={
                    <Radio
                      size="small"
                      defaultChecked={selectedAggregateBy === "carrier_name"}
                    />
                  }
                  label="Carrier"
                />
                <FormControlLabel
                  value="formatted_device_type"
                  control={
                    <Radio
                      size="small"
                      defaultChecked={
                        selectedAggregateBy === "formatted_device_type"
                      }
                    />
                  }
                  label="Device Type"
                />
              </RadioGroup>
            </FormControl>

            <FormControl className="w-[200px]">
              <Select
                size="small"
                variant="outlined"
                labelId="select-data-source-label"
                id="select-data-source"
                value={selectedDataSource}
                onChange={(event) =>
                  setSelectedDataSource(event.target.value as InvoiceDataSource)
                }
              >
                {dataSourceOptions.map((option) => (
                  <MenuItem value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="!ml-4">
              <FormControlLabel
                control={
                  <Switch
                    checked={showTrending}
                    onChange={() => setShowTrending(!showTrending)}
                    size="small"
                  />
                }
                label="Trending"
              />
            </FormControl>
          </div>

          <div className="px-4">
            {renderChart()}

            {renderChartCheckboxes()}

            <div className="mb-4">
              <Button onClick={handleExport} fullWidth variant="contained">
                Export Data
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
