import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { classNames } from "@react-ms-apps/common/utils/styles";

interface HelpModalProps {
  open: boolean;
  onClose: () => void;
}

interface Content {
  type: Variant;
  text: string;
}

const content: Content[] = [
  {
    type: "body1",
    text: "This dashboard is typically the landing page for all managers and administrators. It provides an interactive bar chart graph, a tabular count of devices by carrier and device type and a section which will display pre-configured cost and usage alerts.",
  },
  {
    type: "h2",
    text: "12-Month Bar Graph",
  },
  {
    type: "body1",
    text: "This chart provide two ways to subcategorize the displayed information, by device type and carrier. The chart will dynamically change when the selection is toggled between these subcategory choices. The legend beneath the chart show the categories related to the band colors in the chart. These legend categories are also interactive and you may narrow or expand your displayed values by clicking or unclicking the checkmark adjacent to each legend. Only those with a checkmark will display in the chart.",
  },
  {
    type: "body1",
    text: "Chart display attributes (Total Cost, Equipment Cost, Total Data Usage, etc) are selected from the dropdown located in the center of the chart heading.",
  },
  {
    type: "body1",
    text: "This chart now incorporates the ability to view trending data over multiple years. In the right hand side of the chart banner is a checkbox labeled Trending. This checkbox will only appear if there is at least 15 months of data. When you check this box, the chart will revert to a line graph that plots the chosen attribute from the dropdown with up to 3 years of trending data. In the trending graph the subcategory, Device Type and Carrier are summed to a total value and don’t reflect the subcategory breakouts.",
  },
  {
    type: "body1",
    text: "Finally, at the bottom is an Export button which will produce a spreadsheet of data values for the current chart display view.",
  },
  {
    type: "h2",
    text: "Device by Carrier Counts",
  },
  {
    type: "body1",
    text: "In the upper right hand panel you will find a table with rows matching the number of carriers and columns indicating the identified device type information found in the selected month’s invoice data. The total row will display the sum of the device type categories. Note that where multiple carriers exist and where device in the selected month may have been ported between carriers, their count will include the device in each of the carrier counts indicating that the total row may contain duplicate counts.",
  },
  {
    type: "h2",
    text: "Alerts",
  },
  {
    type: "body1",
    text: "The lower right hand panel displays any pre-defined alerts that exist where at least one device satisfies the alert condition. Alerts will not appear in the chart if there are no qualifying devices for that particular alert. Each alert contains 3 components, the alert name (which also serves as a link to retrieve the detailed information for that alert), the alert threshold (a brief description of the value required to qualify for the alert), and the count of devices that equal or exceed that alerts threshold. The count of qualifying devices appears in blue font.",
  },
  {
    type: "body1",
    text: "For a row-by-row view of the devices and their values that qualified them for inclusion in the alert, you click on the alert description link. This link will transfer you to the report screen which contains all the devices that qualified for that selected attribute (Cost, Data Usage, Zero Usage, etc).",
  },
];

export default function HelpModal({ open, onClose }: HelpModalProps) {
  return (
    <Dialog open={open} scroll="paper" onClose={onClose}>
      <DialogTitle variant="h1">Invoice Summary</DialogTitle>
      <DialogContent className="flex flex-col" dividers>
        {content.map((item, index) => (
          <div className={classNames(item.type === "h2" ? "my-2" : "mb-4")}>
            <Typography key={index} variant={item.type}>
              {item.text}
            </Typography>
          </div>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
