import { TextField } from "@mui/material";
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid";
import { useCallback, useLayoutEffect, useRef } from "react";

export default function DataGridTextField(props: GridRenderEditCellParams) {
  const { id, value, field, hasFocus } = props;

  const apiRef = useGridApiContext();
  const ref = useRef<HTMLInputElement>(null);

  useLayoutEffect(() => {
    if (!ref.current) return;

    if (hasFocus) {
      ref.current.focus();
    }
  }, [hasFocus]);

  const handleValueChange = useCallback(
    async (value: string) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value,
      });
    },
    [apiRef, field, id]
  );

  return (
    <TextField
      variant="standard"
      ref={ref}
      value={value}
      onChange={(e) => handleValueChange(e.target.value)}
    />
  );
}
