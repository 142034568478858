import * as Sentry from "@sentry/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { fetchCopyrightRemoved } from "../../api/assets";
import { classNames } from "../../utils/styles";

export { default as FooterButtonBar } from "./ButtonBar";

interface FooterProps {
  buttonBar?: React.ReactNode;
}

export function Footer({ buttonBar }: FooterProps) {
  const [copyrightRemoved, setCopyrightRemoved] = useState(true);

  const fetchCopyRightRemoved = async () => {
    try {
      const copyRightRemoved = await fetchCopyrightRemoved();
      setCopyrightRemoved(copyRightRemoved);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  useEffect(() => {
    fetchCopyRightRemoved();
  }, []);

  return (
    <div
      data-testid="copyright-wrapper"
      className={classNames(
        "fixed bottom-0 left-0 right-0 flex bg-primary-900 text-white text-right text-xs z-50 min-h-[32px]",
        buttonBar ? "justify-between" : "p-2 justify-end"
      )}
    >
      {buttonBar}

      {copyrightRemoved
        ? ""
        : `Copyright ©2001 - ${moment().year()} MobilSense, Inc. All rights reserved.`}
    </div>
  );
}
