import CancelIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import { Checkbox, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridRenderEditCellParams,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModel,
  GridRowModes,
  GridRowModesModel,
  GridToolbar,
  GridValueGetterParams,
} from "@mui/x-data-grid-pro";
import { DataTestIDs } from "@react-ms-apps/app/src/Constants/data-test-ids";
import {
  DataGridTextField,
  fetchOrderTypeByID,
  fetchOrderTypes,
  getEditableRoles,
  updateOrderTypeByID,
} from "@react-ms-apps/common";
import { useAuth } from "@react-ms-apps/common/providers";
import { useNav } from "@react-ms-apps/common/providers/NavProvider";
import { AuthorizedRoles } from "@react-ms-apps/common/types";
import { OrderTypeDTO } from "@react-ms-apps/common/types/order-type";
import * as Sentry from "@sentry/react";
import { isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

export default function OrderTypesEditorTable() {
  const { user, roles: userRoles } = useAuth();
  const { navData } = useNav();

  const [rows, setRows] = useState<OrderTypeDTO[]>([]);
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [loadingOrderTypes, setLoadingOrderTypes] = useState(true);

  const tableHeight = useMemo(() => {
    const appHeader = document.querySelector("#app-header");
    const appHeaderHeight = appHeader?.clientHeight ?? 0;

    const pageHeader = document.querySelector("#order-types-management-header");
    const pageHeaderHeight = pageHeader?.clientHeight ?? 0;

    const description = document.querySelector(
      "#order-types-management-description"
    );
    const descriptionHeight = description?.clientHeight ?? 0;

    // set a default height
    if (!appHeader || !pageHeader || !description) return 500;

    return (
      window.innerHeight -
      (appHeaderHeight + pageHeaderHeight + descriptionHeight + 200)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navData]);

  const editableRoles = useMemo(() => {
    if (!user) return [];

    return getEditableRoles(userRoles, [
      AuthorizedRoles.MS_sysadmin,
      AuthorizedRoles.MS_telcoadmin,
      AuthorizedRoles.MS_admin,
      AuthorizedRoles.MS_manager,
      AuthorizedRoles.Authenticated,
    ]);
  }, [user, userRoles]);

  const getOrderTypesEtags = useCallback(async (rows: OrderTypeDTO[]) => {
    let etagFetchErrorTriggered = false;

    // fetch etags for each row
    rows.forEach(async (row) => {
      // check if etag exists
      if (row.etag) return;

      try {
        const { orderType, etag } = await fetchOrderTypeByID(row.order_type_id);

        const updatedRow = { ...orderType, etag };
        setRows((prevRows) => {
          return prevRows.map((prevRow) => {
            if (prevRow.order_type_id === updatedRow.order_type_id) {
              return updatedRow;
            }

            return prevRow;
          });
        });
      } catch (error) {
        Sentry.captureException(error);

        if (!etagFetchErrorTriggered) {
          toast.error("Failed to fetch etag");
        }
        etagFetchErrorTriggered = true;
      }
    });
  }, []);

  const getOrderTypes = useCallback(async () => {
    setLoadingOrderTypes(true);

    let orderTypes: OrderTypeDTO[] = [];

    try {
      const orderTypesData = await fetchOrderTypes();

      orderTypes = orderTypesData
        // remove list of unused order types
        .filter((orderType) => !orderType.not_implemented)
        // sort alphabetically by utility name
        .sort((a, b) => {
          if (a.description < b.description) {
            return -1;
          }

          if (a.description > b.description) {
            return 1;
          }

          return 0;
        });

      setRows(orderTypes);
      getOrderTypesEtags(orderTypes);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingOrderTypes(false);
    }
  }, [getOrderTypesEtags]);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditStart = async (params: { id: GridRowId }) => {
    // fetch etag for the row
    const { id } = params;

    try {
      const { orderType, etag } = await fetchOrderTypeByID(id as number);

      const updatedRow = { ...orderType, etag };
      setRows((prevRows) => {
        return prevRows.map((prevRow) => {
          if (prevRow.order_type_id === updatedRow.order_type_id) {
            return updatedRow;
          }

          return prevRow;
        });
      });
    } catch (error) {
      Sentry.captureException(error);

      toast.error("Failed to fetch etag");
    }
  };

  const handleEditClick = useCallback(
    (id: GridRowId) => async () => {
      handleEditStart({ id });
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [rowModesModel]
  );

  const updateOrderType = useCallback(
    async (id: number, orderType: OrderTypeDTO, etag: string) => {
      try {
        const { etag: updatedEtag, orderType: updatedOrderType } =
          await updateOrderTypeByID(id, orderType, etag);
        toast.success("Updated active");

        // update the etag
        const updatedRow = { ...updatedOrderType, etag: updatedEtag };
        setRows((prevRows) => {
          return prevRows.map((prevRow) => {
            if (prevRow.order_type_id === updatedRow.order_type_id) {
              return updatedRow;
            }

            return prevRow;
          });
        });
      } catch (error) {
        Sentry.captureException(error);
        toast.error("Failed to update active");
      }
    },
    []
  );

  const handleActiveChange = useCallback(
    async (id: number, active: boolean, row: OrderTypeDTO) => {
      const { etag } = row;
      if (!etag) {
        toast.warn("etag is required");
        return;
      }

      const orderType = { ...row, active };
      await updateOrderType(id, orderType, etag);
    },
    [updateOrderType]
  );

  const handleAutoApproveChange = useCallback(
    async (id: number, auto_approve: boolean, row: OrderTypeDTO) => {
      const { etag } = row;
      if (!etag) {
        toast.warn("etag is required");
        return;
      }

      const orderType = { ...row, auto_approve };
      await updateOrderType(id, orderType, etag);
    },
    [updateOrderType]
  );

  const handleAuthorizedRoleChange = useCallback(
    async (
      id: number,
      role: AuthorizedRoles,
      checked: boolean,
      row: OrderTypeDTO
    ) => {
      const { etag = "" } = row;
      if (!etag) {
        toast.warn("etag is required");
        return;
      }

      const getFinalRoles = (): string => {
        if (role === AuthorizedRoles.Authenticated) {
          // remove all other roles
          if (checked) {
            return AuthorizedRoles.Authenticated;
          }

          // otherwise return all roles (available to this table) except authenticated
          return [
            AuthorizedRoles.MS_sysadmin,
            AuthorizedRoles.MS_telcoadmin,
            AuthorizedRoles.MS_admin,
            AuthorizedRoles.MS_manager,
          ].join(",");
        }

        let roles: string[] = [];
        roles = row.authorized_roles.split(",");

        // add or remove the role
        if (checked) {
          roles.push(role);
        } else {
          roles.splice(roles.indexOf(role), 1);
        }

        // only allow unique roles
        roles
          .filter(
            (role, index, self) => index === self.findIndex((r) => r === role)
          )
          // remove empty strings
          .filter((role) => role);

        return roles.join(",");
      };

      const roles = getFinalRoles();

      const orderType = { ...row, authorized_roles: roles };
      await updateOrderType(id, orderType, etag);
    },
    [updateOrderType]
  );

  const processRowUpdate = async (
    newRow: GridRowModel<OrderTypeDTO>,
    oldRow: GridRowModel<OrderTypeDTO>
  ) => {
    const updatedRow = { ...newRow };

    if (isEqual(newRow, oldRow)) return updatedRow;
    if (!updatedRow.etag) {
      toast.warn("etag is required");

      return oldRow;
    }

    // used only for the update call
    // deletes the derived fields from the order type
    const updatedOrderType = { ...updatedRow };

    try {
      await updateOrderType(
        updatedOrderType.order_type_id,
        updatedOrderType,
        updatedRow.etag
      );

      return updatedRow;
    } catch (error) {
      Sentry.captureException(error);

      return oldRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  useEffect(() => {
    getOrderTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns: GridColDef[] = useMemo(() => {
    if (!user) return [];

    const columns: GridColDef[] = [
      {
        field: "description",
        headerName: "Description",
        type: "string",
        width: 230,
        align: "left",
        headerAlign: "left",
        editable: false,
      },
      {
        field: "active",
        type: "boolean",
        width: 60,
        headerName: "Active",
        editable: false, // disabled to prevent native editing
        renderCell: (params: GridRenderEditCellParams) => {
          const { row } = params;
          const { active, etag } = row;

          const defaultChecked = Boolean(active);

          return (
            <Switch
              disabled={!etag}
              onChange={(e, active) =>
                handleActiveChange(row.order_type_id, active, row)
              }
              defaultChecked={defaultChecked}
            />
          );
        },
      },
      {
        field: "auto_approve",
        type: "boolean",
        width: 110,
        headerName: "Auto Approve",
        editable: false, // disabled to prevent native editing
        renderCell: (params: GridRenderEditCellParams) => {
          const { row } = params;
          const { etag, auto_approve } = row;

          const defaultChecked = Boolean(auto_approve);

          return (
            <Switch
              disabled={!etag}
              onChange={(e, active) =>
                handleAutoApproveChange(row.order_type_id, active, row)
              }
              defaultChecked={defaultChecked}
            />
          );
        },
      },
      {
        field: "custom_description",
        type: "string",
        headerName: "Custom Description",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <DataGridTextField {...params} />
        ),
      },
      {
        field: "hover_description",
        type: "string",
        headerName: "Hover Description",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <DataGridTextField {...params} />
        ),
      },
      {
        field: "custom_report_description",
        type: "string",
        headerName: "Custom Report Description",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <DataGridTextField {...params} />
        ),
      },
      {
        field: "special_instructions",
        type: "string",
        headerName: "Special Instructions",
        width: 200,
        editable: true,
        renderEditCell: (params: GridRenderEditCellParams) => (
          <DataGridTextField {...params} />
        ),
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 100,
        cellClassName: "actions",
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: "primary.main",
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];

    const roleColumns: GridColDef[] = [
      {
        field: AuthorizedRoles.MS_sysadmin,
        headerName: "Sys Admins",
        type: "boolean",
        width: 100,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => {
          const { row } = params;
          const { etag } = row || {};
          const authorized_roles: string = row.authorized_roles;

          const isRoleAuthorized =
            authorized_roles.includes(AuthorizedRoles.MS_sysadmin) ||
            authorized_roles.includes(AuthorizedRoles.Authenticated);

          const isAuthorizedOn = !!authorized_roles.includes(
            AuthorizedRoles.Authenticated
          );

          const isDisabled = !etag || isAuthorizedOn;

          return (
            <Switch
              disabled={isDisabled}
              onChange={(e, active) =>
                handleAuthorizedRoleChange(
                  row.order_type_id,
                  AuthorizedRoles.MS_sysadmin,
                  active,
                  row
                )
              }
              checked={isRoleAuthorized}
              value={isRoleAuthorized}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          );
        },
      },
      {
        field: AuthorizedRoles.MS_telcoadmin,
        type: "boolean",
        headerName: "Telco Admins",
        width: 100,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => {
          const { row } = params;
          const { etag } = row || {};
          const authorized_roles: string = row.authorized_roles;

          const isRoleAuthorized =
            authorized_roles.includes(AuthorizedRoles.MS_telcoadmin) ||
            authorized_roles.includes(AuthorizedRoles.Authenticated);

          const isAuthorizedOn = !!authorized_roles.includes(
            AuthorizedRoles.Authenticated
          );

          const isDisabled = !etag || isAuthorizedOn;

          return (
            <Switch
              disabled={isDisabled}
              onChange={(e, active) =>
                handleAuthorizedRoleChange(
                  row.order_type_id,
                  AuthorizedRoles.MS_telcoadmin,
                  active,
                  row
                )
              }
              checked={isRoleAuthorized}
              value={isRoleAuthorized}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          );
        },
      },
      {
        field: AuthorizedRoles.MS_admin,
        type: "boolean",
        headerName: "Admins",
        width: 60,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => {
          const { row } = params;
          const { etag } = row || {};
          const authorized_roles: string = row.authorized_roles;

          const isRoleAuthorized =
            authorized_roles.includes(AuthorizedRoles.MS_admin) ||
            authorized_roles.includes(AuthorizedRoles.Authenticated);

          const isAuthorizedOn = !!authorized_roles.includes(
            AuthorizedRoles.Authenticated
          );

          const isDisabled = !etag || isAuthorizedOn;

          return (
            <Switch
              disabled={isDisabled}
              onChange={(e, active) =>
                handleAuthorizedRoleChange(
                  row.order_type_id,
                  AuthorizedRoles.MS_admin,
                  active,
                  row
                )
              }
              checked={isRoleAuthorized}
              value={isRoleAuthorized}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          );
        },
      },
      {
        field: AuthorizedRoles.MS_manager,
        type: "boolean",
        headerName: "Managers",
        width: 80,
        editable: false, // disabled to prevent native editing
        align: "left",
        headerAlign: "left",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => {
          const { row } = params;
          const { etag } = row || {};
          const authorized_roles: string = row.authorized_roles;

          const isRoleAuthorized =
            authorized_roles.includes(AuthorizedRoles.MS_manager) ||
            authorized_roles.includes(AuthorizedRoles.Authenticated);

          const isAuthorizedOn = !!authorized_roles.includes(
            AuthorizedRoles.Authenticated
          );

          const isDisabled = !etag || isAuthorizedOn;

          return (
            <Switch
              disabled={isDisabled}
              onChange={(e, active) =>
                handleAuthorizedRoleChange(
                  row.order_type_id,
                  AuthorizedRoles.MS_manager,
                  active,
                  row
                )
              }
              checked={isRoleAuthorized}
              value={isRoleAuthorized}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
          );
        },
      },
    ];

    // add role columns based on editable roles
    const editableRoleColumns = roleColumns.filter((roleColumn) =>
      editableRoles.includes(roleColumn.field as AuthorizedRoles)
    );

    // add authenticated column
    // add Authenticated column if user is sysadmin or backoffice
    if (
      userRoles.includes(AuthorizedRoles.MS_sysadmin) ||
      userRoles.includes(AuthorizedRoles.MS_backoffice)
    ) {
      editableRoleColumns.push({
        field: AuthorizedRoles.Authenticated,
        type: "boolean",
        headerName: "Authenticated",
        description:
          "Changing this role will affect all roles, including roles not shown here.",
        width: 130,
        editable: false, // disabled to prevent native editing
        align: "center",
        headerAlign: "center",
        cellClassName: () => {
          return "MuiDataGrid-booleanCell !pl-0";
        },
        renderCell: (params) => {
          const { row } = params;
          const { authorized_roles, etag } = row;

          const defaultChecked = authorized_roles.includes(
            AuthorizedRoles.Authenticated
          );

          return (
            <Checkbox
              disabled={!etag}
              onChange={(e, active) =>
                handleAuthorizedRoleChange(
                  row.order_type_id,
                  AuthorizedRoles.Authenticated,
                  active,
                  row
                )
              }
              defaultChecked={defaultChecked}
            />
          );
        },
      });
    }

    if (editableRoleColumns.length > 0) {
      // add role columns before the last column
      columns.splice(columns.length - 1, 0, ...editableRoleColumns);
    }

    // add date column
    columns.push({
      field: "updated_at",
      type: "dateTime",
      headerName: "Last Updated",
      minWidth: 200,
      editable: false,
      valueGetter: (params: GridValueGetterParams) =>
        new Date(params.row.updated_at),
    });

    return columns;
  }, [
    editableRoles,
    handleActiveChange,
    handleAuthorizedRoleChange,
    handleAutoApproveChange,
    handleCancelClick,
    handleEditClick,
    handleSaveClick,
    rowModesModel,
    user,
    userRoles,
  ]);

  return (
    <Box
      sx={{
        height: tableHeight,
        width: "100%",
        "& .actions": {
          color: "text.secondary",
        },
        "& .textPrimary": {
          color: "text.primary",
        },
        "& .MuiDataGrid-booleanCell[data-value='true']": {
          color: "success.main",
        },
        "& .MuiDataGrid-booleanCell[data-value='false']": {
          color: "error.main",
        },
      }}
    >
      <DataGridPro
        loading={loadingOrderTypes}
        data-testid={DataTestIDs.ORDER_TYPE_EDITOR_TABLE}
        getRowId={(row) => row.order_type_id}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: GridToolbar,
        }}
        pinnedColumns={{
          // left: ["description"],
          right: ["actions"],
        }}
        onRowEditStart={handleEditStart}
      />
    </Box>
  );
}
