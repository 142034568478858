import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import {
  FooterButtonBar,
  PageContainer,
  ReportListItem,
  fetchReportNames,
  fetchStatementMonthsList,
  fetchUsageAndCostAlerts,
  fetchUsageAndCostDataSets,
} from "@react-ms-apps/common";
import { useFooter } from "@react-ms-apps/common/providers";
import { UsageAndCost, UsageAndCostAlert } from "@react-ms-apps/common/types";
import * as Sentry from "@sentry/react";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import CarriersDevicesTable from "./CarriersDevicesTable";
import InvoiceSummaryChart from "./Chart";
import HelpModal from "./HelpModal";
import TutorialModal from "./TutorialModal";
import UsageCostAlertTable from "./UsageCostAlertTable";

export default function InvoiceSummary() {
  const { setButtonBar } = useFooter();

  const [showHelpDialog, setShowHelpDialog] = useState<boolean>(false);
  const [showTutorialDialog, setShowTutorialDialog] = useState<boolean>(false);
  const [loadingMonths, setLoadingMonths] = useState<boolean>(false);
  const [selectedStatementMonth, setSelectedStatementMonth] =
    useState<string>("");
  const [statementMonths, setStatementMonths] = useState<string[]>([]);

  const [loadingUsageCostData, setLoadingUsageCostData] =
    useState<boolean>(false);
  const [usageCostData, setUsageCostData] = useState<UsageAndCost[]>([]);

  const [loadingUsageCostAlertData, setLoadingUsageCostAlertData] =
    useState<boolean>(false);
  const [usageCostAlertData, setUsageCostAlertData] = useState<
    UsageAndCostAlert[]
  >([]);

  const [loadingReportOptions, setLoadingReportOptions] =
    useState<boolean>(false);
  const [reportOptions, setReportOptions] = useState<ReportListItem[]>([]);

  const getAvailableMonths = useCallback(async () => {
    setLoadingMonths(true);

    try {
      const months = await fetchStatementMonthsList();

      // sort months in descending order
      months.sort((a, b) => moment(b).diff(moment(a)));

      setStatementMonths(months);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingMonths(false);
    }
  }, []);

  const getUsageAndCostData = useCallback(async () => {
    setLoadingUsageCostData(true);

    try {
      const usageAndCostData = await fetchUsageAndCostDataSets(
        selectedStatementMonth
      );
      setUsageCostData(usageAndCostData);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingUsageCostData(false);
    }
  }, [selectedStatementMonth]);

  const getUsageAndCostAlertData = useCallback(async () => {
    setLoadingUsageCostAlertData(true);

    try {
      const usageAndCostAlertData = await fetchUsageAndCostAlerts(
        selectedStatementMonth
      );
      setUsageCostAlertData(usageAndCostAlertData);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingUsageCostAlertData(false);
    }
  }, [selectedStatementMonth]);

  const getReportOptions = useCallback(async () => {
    setLoadingReportOptions(true);

    try {
      const reportNames = await fetchReportNames();
      setReportOptions(reportNames);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingReportOptions(false);
    }
  }, []);

  // fetch available months
  useEffect(() => {
    getAvailableMonths();
  }, [getAvailableMonths]);

  // fetch usage and cost data for selected month
  useEffect(() => {
    if (selectedStatementMonth) {
      getUsageAndCostData();
      getUsageAndCostAlertData();
    }
  }, [getUsageAndCostAlertData, getUsageAndCostData, selectedStatementMonth]);

  // auto-select the first month
  useEffect(() => {
    if (statementMonths.length > 0) {
      setSelectedStatementMonth(statementMonths[0]);
    }
  }, [statementMonths]);

  // setup the footer button bar
  useEffect(() => {
    setButtonBar(
      <FooterButtonBar
        onHelpClick={() => setShowHelpDialog(true)}
        onTutorialClick={() => setShowTutorialDialog(true)}
      />
    );
  }, [setButtonBar]);

  // fetch report options
  useEffect(
    () => {
      getReportOptions();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const selectedMonth = useMemo(
    () => moment(selectedStatementMonth).toDate(),
    [selectedStatementMonth]
  );

  const loadingInvoiceData = useMemo(() => {
    return loadingUsageCostData || loadingUsageCostAlertData;
  }, [loadingUsageCostAlertData, loadingUsageCostData]);

  return (
    <>
      <PageContainer className="flex-col" header="Invoice Summary">
        <div className="flex flex-row justify-between">
          <FormControl className="w-[200px]">
            <InputLabel id="select-month-label">Select Month</InputLabel>

            <Select
              disabled={loadingMonths}
              labelId="select-month-label"
              id="select-month"
              label="Select Month"
              value={selectedStatementMonth}
              onChange={(event) =>
                setSelectedStatementMonth(event.target.value as string)
              }
            >
              {statementMonths.map((month) => (
                <MenuItem value={month}>{month}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl className="w-[200px]">
            <InputLabel id="select-report">Select Report</InputLabel>

            <Select
              disabled={loadingReportOptions}
              labelId="select-report"
              id="select-report"
              label="Select Report"
              // opens the report in the same tab
              onChange={(event) =>
                event.target.value &&
                window.open(event.target.value as string, "_parent")
              }
            >
              {reportOptions.map((option) => (
                <MenuItem value={option.url || ""}>
                  {option.display_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {loadingInvoiceData && (
          <div className="flex flex-1 flex-col items-center">
            <CircularProgress className="mt-10" size={60} />
          </div>
        )}

        {!loadingInvoiceData && (
          <div className="flex flex-row mt-4 gap-x-4">
            {usageCostData.length > 0 && (
              <InvoiceSummaryChart
                usageCostData={usageCostData}
                selectedDate={selectedMonth}
              />
            )}

            <div className="w-1/2 mb-8">
              {usageCostData.length > 0 && (
                <CarriersDevicesTable
                  usageCostData={usageCostData}
                  selectedMonth={selectedMonth}
                />
              )}

              {usageCostAlertData.length > 0 && (
                <UsageCostAlertTable
                  usageCostAlertData={usageCostAlertData}
                  selectedDate={selectedMonth}
                />
              )}
            </div>
          </div>
        )}
      </PageContainer>

      <HelpModal
        open={showHelpDialog}
        onClose={() => setShowHelpDialog(false)}
      />

      <TutorialModal
        open={showTutorialDialog}
        onClose={() => setShowTutorialDialog(false)}
      />
    </>
  );
}
