import { ROUTES } from "@react-ms-apps/common";
import React from "react";
import { Route, Routes } from "react-router-dom";
import InvoiceSummary from "../Components/InvoiceSummary";
import NotFoundPage from "../Components/NotFoundPage";

export default function DashboardApp() {
  return (
    <Routes>
      <Route
        path={ROUTES.DASHBOARD.INVOICE_SUMMARY}
        element={<InvoiceSummary />}
      />

      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
