import csvDownload from "json-to-csv-export";
import { Series } from "./utils";

interface ExportDataItem {
  month: string;
  [key: string]: string | number;
}

export const exportUsageAsCsv = (seriesData: Series) => {
  const exportData: Array<ExportDataItem> = [];

  // create headers
  const headers = ["Month"];

  for (let i = 0; i < seriesData.items.length; i++) {
    const item = seriesData.items[i];
    headers.push(item.label);
  }

  for (let i = 0; i < seriesData.items[0].data.length; i++) {
    const month = seriesData.xLabels[i];

    const exportDataItem: ExportDataItem = {
      month,
    };

    for (let j = 0; j < seriesData.items.length; j++) {
      const aggregate = seriesData.items[j].label;
      const aggregateValue = seriesData.items[j].data[i];

      exportDataItem[aggregate] = aggregateValue;
    }

    exportData.push(exportDataItem);
  }

  const dataToConvert = {
    data: exportData,
    filename: "Invoice_Summary",
    delimiter: ",",
    headers,
  };

  csvDownload(dataToConvert);
};
