import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface TutorialModalProps {
  open: boolean;
  onClose: () => void;
}

export default function TutorialModal({ open, onClose }: TutorialModalProps) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle variant="h1">Help Video</DialogTitle>
      <DialogContent dividers>
        <iframe
          width="840"
          height="635"
          src="https://www.youtube.com/embed/FhD1kDiyo2w?si=Z97hnHXpkoeglIbP"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
