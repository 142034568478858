import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  PageContainer,
  ROUTES,
  getClientDB,
  uploadCsvToProcessor,
} from "@react-ms-apps/common";
import {
  UtilityDTO,
  fetchUtilities,
} from "@react-ms-apps/common/api/utilities";
import { UploadProcessTypes } from "@react-ms-apps/common/constants/uploadProcessTypes";
import { useOrg } from "@react-ms-apps/common/providers";
import { OrgInfoDTO } from "@react-ms-apps/common/types";
import { bytesToSize } from "@react-ms-apps/common/utils/file-size";
import { classNames } from "@react-ms-apps/common/utils/styles";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Papa from "papaparse";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { usePageTitle } from "../../Providers/PageTitleProvider";
import ChooseFileDialog from "./ChooseFileDialog";
import SuccessDialog from "./SuccessDialog";
import { dynamicFields, uploadProcessUIs } from "./uploadProcessUIs";
import { getCurrentTotalPath, getProcessTypeFromPath } from "./utils";

export default function UploadProcessor() {
  const { org } = useOrg();
  const navigate = useNavigate();
  const location = useLocation();
  const { setTitle } = usePageTitle();

  const clientDB = getClientDB();

  // modal state
  const [fileModalOpen, setFileModalOpen] = useState(false);

  // success dialog
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const [selectedProcessType, setSelectedProcessType] =
    useState<UploadProcessTypes>(getProcessTypeFromPath(location));

  // local state
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [allowUpdatesBlankValues, setAllowUpdatesBlankValues] = useState(false);
  const [parsedCsv, setParsedCsv] = useState<string[][]>([]);
  const [responseCsv, setResponseCsv] = useState<string[][]>([]);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [missingFields, setMissingFields] = useState<string[]>([]);

  const [utilities, setUtilities] = useState<UtilityDTO[]>([]);

  const [ignoredHeaders, setIgnoredHeaders] = useState<string[]>([]);

  /** API */
  const getUtilities = async () => {
    try {
      const utilities = await fetchUtilities();
      setUtilities(utilities);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  /** MEMOIZED VALUES */

  const uploadProcessItem = useMemo(() => {
    return uploadProcessUIs.find((uploadProcessUI) => {
      return uploadProcessUI.processType === selectedProcessType;
    });
  }, [selectedProcessType]);

  const uploadProcessTitle = useMemo(() => {
    return uploadProcessItem?.title || "";
  }, [uploadProcessItem]);

  const uploadProcessDescription = useMemo(() => {
    return uploadProcessItem?.description || [];
  }, [uploadProcessItem]);

  const uploadProcessFields = useMemo(() => {
    if (!uploadProcessItem || !org) return [];

    const fields = [...uploadProcessItem.fields];

    if (uploadProcessItem.hasDynamicFields) {
      for (const field of dynamicFields) {
        if (org[`${field}_active` as keyof OrgInfoDTO]) {
          fields.push({
            label: org[`${field}_heading` as keyof OrgInfoDTO],
            required: false,
          });
        }
      }
    }

    // sort alphabetically with required fields at the top
    return (
      fields
        // sort required fields to the top
        .sort((a, b) => {
          if (a.required && !b.required) {
            return -1;
          }
          if (!a.required && b.required) {
            return 1;
          }
          return 0;
        })
    );
  }, [uploadProcessItem, org]);

  const uploadProcessCanAllowBlankValues = useMemo(() => {
    return uploadProcessItem?.allowBlankValuesFields || false;
  }, [uploadProcessItem]);

  const orginalFileName = useMemo(() => {
    let fileName = "";

    // check if there is a file extension
    // if so, then the fileName should not include the extension
    if (selectedFile?.name.includes(".")) {
      fileName = selectedFile?.name.split(".")[0];
    } else {
      fileName = selectedFile?.name || "";
    }

    return fileName;
  }, [selectedFile]);

  /**
   * HANDLERS
   */
  const handleFileUpload = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedFile || !parsedCsv) return;

    try {
      setIsUploading(true);
      // upload the file to the server
      const response = await uploadCsvToProcessor(
        selectedFile,
        selectedProcessType,
        allowUpdatesBlankValues
      );

      if (typeof response === "string") {
        // parse the CSV string
        const parsedCsv = Papa.parse<string[]>(response, {
          skipEmptyLines: true,
        });

        setResponseCsv(parsedCsv.data);
      }

      setSuccessDialogOpen(true);
    } catch (error) {
      // handle error
      let errorMessage = "";

      if (axios.isAxiosError(error)) {
        errorMessage =
          error.response?.data?.d?.errors?.join("\n") || error.message;
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      if (errorMessage) {
        toast.error(errorMessage);
        setErrorMessage(errorMessage);
      }
    } finally {
      setIsUploading(false);
    }
  };

  const handleSuccessDialogClose = () => {
    setSuccessDialogOpen(false);
    setSelectedFile(null);
    setParsedCsv([]);
    setResponseCsv([]);
  };

  const parseCsv = (data: string[][]) => {
    // only accept the allowed headers
    const allowedHeaders = uploadProcessFields.map((field) => field.label);
    const headers: string[] = data?.[0] ?? [];
    const filteredHeaders = headers.filter((header) =>
      allowedHeaders.includes(header)
    );
    const ignoredHeaders = headers.filter(
      (header) => !allowedHeaders.includes(header)
    );

    // filter out the cells that don't have allowed headers
    const rows = data.map((row: string[]) => {
      return row.filter((cell, index) => {
        return filteredHeaders.includes(headers[index]);
      });
    });

    return {
      headers: filteredHeaders,
      ignoredHeaders,
      rows,
    };
  };

  const handleNewFile = () => {
    if (!selectedFile) {
      setParsedCsv([]);
      return;
    }

    const reader = new FileReader();

    reader.onload = async (e) => {
      try {
        const text = e.target?.result;
        Papa.parse(text as string, {
          complete: function (results: Papa.ParseResult<string[]>) {
            const { headers, ignoredHeaders, rows } = parseCsv(results.data);

            setParsedCsv([headers, ...rows.slice(1, rows.length)]);
            setIgnoredHeaders(ignoredHeaders);
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          toast.error(error.message);
          setErrorMessage(error.message);
        }
      }
    };

    reader.readAsText(selectedFile);
  };

  const getMissingFields = useCallback(
    (parsedCsv: string[][]): string[] => {
      // check if the file is valid by checking the headers against the required fields in the upload process
      const headers = parsedCsv?.[0] ?? [];
      const requiredFields = uploadProcessFields.filter(
        (field) => field.required
      );

      if (requiredFields.length === 0) return [];

      // check if the headers contain all the required fields
      const isValid = requiredFields.every((requiredField) => {
        return headers.includes(requiredField.label);
      });

      // if the file is not valid, return the required fields that are missing
      if (!isValid) {
        return requiredFields
          .filter((requiredField) => !headers.includes(requiredField.label))
          .map((requiredField) => requiredField.label);
      }

      return [];
    },
    [uploadProcessFields]
  );

  const getProcessName = useCallback(
    (processTypeName: string) => {
      const utility = utilities.find((u) => u.utility_name === processTypeName);

      if (!utility) return processTypeName;

      return utility.custom_utility_name || utility.utility_name;
    },
    [utilities]
  );

  /** USE EFFECTS */

  // check for any missing fields
  useEffect(() => {
    if (!parsedCsv) {
      setMissingFields([]);
      return;
    }

    // check if the file is valid
    const missingFields = getMissingFields(parsedCsv);
    setMissingFields(missingFields);
  }, [getMissingFields, parsedCsv, selectedProcessType]);

  // redirect user to appropriate URL based on the selected process
  useEffect(() => {
    let nextPath = `${ROUTES.UTILITY.ROOT}${ROUTES.UTILITY.UPLOAD_PROCESSOR}?processType=${selectedProcessType}`;

    if (nextPath !== "" && nextPath !== getCurrentTotalPath(location)) {
      // navigate to the next path
      nextPath = `/${nextPath}`;
      navigate(nextPath);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProcessType, clientDB]);

  // watch for changes to file to parse the CSV and display preview
  useEffect(() => {
    if (selectedFile === null) return;

    handleNewFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  // fetch utilities
  useEffect(() => {
    getUtilities();
  }, []);

  // set document page title
  useEffect(() => {
    setTitle("Upload Processor");
  }, [setTitle]);

  return (
    <>
      <PageContainer
        header="Upload Processor"
        className="flex-1 flex-col pb-14"
        description={
          <>
            <Typography marginBottom={1}>
              Use this page to perform bulk edits to the database. Select a
              process type, choose a file, and upload.
            </Typography>
            <Typography marginBottom={1}>
              When you have selected a file, you will see a preview of the first
              5 rows of the file. If the file is valid, you will be able to
              upload it.
            </Typography>
          </>
        }
      >
        <Box
          component="form"
          role="form"
          onSubmit={handleFileUpload}
          className="w-full"
        >
          <div className="flex flex-row gap-4 mb-4">
            <FormControl className="!w-80">
              <InputLabel id="process-type-label">Process Type</InputLabel>
              <Select
                labelId="process-type-label"
                id="process-type"
                value={selectedProcessType}
                label="Process Type"
                onChange={(e) =>
                  setSelectedProcessType(e.target.value as UploadProcessTypes)
                }
              >
                {uploadProcessUIs.map((processUI) => (
                  <MenuItem
                    key={processUI.processType}
                    value={processUI.processType}
                  >
                    {getProcessName(processUI.title)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!!selectedProcessType && (
              <>
                <Button
                  variant="outlined"
                  className="whitespace-nowrap min-w-fit !px-8"
                  onClick={() => setFileModalOpen(true)}
                >
                  Choose File
                </Button>

                <LoadingButton
                  type="submit"
                  variant="contained"
                  className="whitespace-nowrap min-w-fit !px-8"
                  disabled={isUploading || !selectedFile}
                  loading={isUploading}
                >
                  Upload
                </LoadingButton>
              </>
            )}
          </div>

          <Box className="w-full">
            {uploadProcessCanAllowBlankValues && (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  label="Permit updates with blank values"
                  id="permit"
                  className="mb-3"
                  value={allowUpdatesBlankValues ? "true" : "false"}
                  onChange={(e, checked) => setAllowUpdatesBlankValues(checked)}
                />
              </FormGroup>
            )}
          </Box>
        </Box>

        {parsedCsv && parsedCsv.length > 0 && (
          <>
            <div className="flex flex-col mt-4 mb-8 gap-2">
              {errorMessage && (
                <Alert data-testid="csv-error-message" severity="warning">
                  <Typography>
                    <strong>Warning:</strong> {errorMessage}
                  </Typography>
                </Alert>
              )}

              {missingFields.length > 0 && (
                <Alert
                  data-testid="csv-error-missing-fields"
                  severity="warning"
                >
                  <Typography>
                    <strong>Warning:</strong> The file is missing the following
                    required fields: <strong>{missingFields.join(", ")}</strong>
                  </Typography>
                  <Typography>
                    All required fields must be present as column headers in
                    order to process the file.
                  </Typography>
                </Alert>
              )}

              {missingFields.length === 0 &&
                !errorMessage &&
                !!selectedFile && (
                  <Alert data-testid="csv-valid-message" severity="success">
                    <Typography>
                      <strong>Success:</strong> The file is valid and can be
                      uploaded.
                    </Typography>
                  </Alert>
                )}

              {ignoredHeaders.length > 0 && (
                <Alert data-testid="csv-ignored-headers" severity="info">
                  <Typography>
                    The following headers will be ignored:{" "}
                    <strong>{ignoredHeaders.join(", ")}</strong>
                  </Typography>
                </Alert>
              )}
            </div>

            <Typography variant="h5">Upload Details</Typography>

            <TableContainer className="mb-8">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>File</strong>
                  </TableCell>
                  <TableCell>File Size</TableCell>
                  <TableCell>Process</TableCell>
                  {uploadProcessCanAllowBlankValues && (
                    <TableCell>Allow Blank Values</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{selectedFile?.name}</TableCell>
                  <TableCell>{bytesToSize(selectedFile?.size || 0)}</TableCell>
                  <TableCell>{uploadProcessTitle}</TableCell>
                  {uploadProcessCanAllowBlankValues && (
                    <TableCell>
                      {allowUpdatesBlankValues ? "Yes" : "No"}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </TableContainer>

            <Typography variant="h5" className="!mb-2">
              Preview
            </Typography>

            <TableContainer component={Paper} data-testid="csv-table-preview">
              <Table>
                <TableHead>
                  <TableRow>
                    {parsedCsv?.[0].map((header, index) => (
                      <TableCell
                        className={classNames("whitespace-nowrap")}
                        key={index}
                      >
                        {header}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {parsedCsv?.slice(1, 6).map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <TableCell key={cellIndex}>{cell}</TableCell>
                      ))}
                    </TableRow>
                  ))}

                  {parsedCsv?.length > 6 && (
                    <TableRow>
                      <TableCell colSpan={parsedCsv[0].length}>
                        <strong>...</strong>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </PageContainer>

      {!!fileModalOpen && (
        <ChooseFileDialog
          open={fileModalOpen}
          onClose={() => setFileModalOpen(false)}
          instructions={uploadProcessDescription}
          fields={uploadProcessFields}
          file={selectedFile}
          onChange={setSelectedFile}
        />
      )}

      {successDialogOpen && (
        <SuccessDialog
          onClose={handleSuccessDialogClose}
          responseCsv={responseCsv}
          orginalFileName={orginalFileName}
        />
      )}
    </>
  );
}
