import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { UsageAndCost } from "@react-ms-apps/common/types";
import moment from "moment";
import React, { useMemo } from "react";

interface CarriersDevicesTableProps {
  usageCostData: UsageAndCost[];
  selectedMonth: Date;
}

export default function CarriersDevicesTable({
  usageCostData,
  selectedMonth,
}: CarriersDevicesTableProps) {
  const selectedMonthUsageCostData = useMemo(() => {
    return usageCostData.filter((item) => {
      const invoiceDate = moment(item.invoice_date).toDate();

      const month1 = selectedMonth.getMonth();
      const month2 = invoiceDate.getMonth();

      const year1 = selectedMonth.getFullYear();
      const year2 = invoiceDate.getFullYear();

      const isSame = month1 === month2 && year1 === year2;
      return isSame;
    });
  }, [selectedMonth, usageCostData]);

  const { carriersDevicesMap, availableCarriers, availableDevices } =
    useMemo(() => {
      const availableDevices = new Set<string>();
      const availableCarriers = new Set<string>();

      const carriersDevicesMap: {
        carriers: {
          [key: string]: {
            devices: {
              [key: string]: number;
            };
            total: number;
          };
        };
        total: number;
      } = {
        carriers: {},
        total: 0,
      };

      for (let i = 0; i < selectedMonthUsageCostData.length; i++) {
        const item = selectedMonthUsageCostData[i];

        const carrierName = item.carrier_name;
        if (!availableCarriers.has(carrierName)) {
          availableCarriers.add(carrierName);
        }

        const device = item.formatted_device_type;
        if (!availableDevices.has(device)) {
          availableDevices.add(device);
        }

        if (!carriersDevicesMap.carriers[carrierName]) {
          carriersDevicesMap.carriers[carrierName] = {
            devices: {},
            total: 0,
          };
        }
        if (!carriersDevicesMap.carriers[carrierName].devices[device]) {
          carriersDevicesMap.carriers[carrierName].devices[device] = 0;
        }

        carriersDevicesMap.carriers[carrierName].devices[device] +=
          item.device_count;
        carriersDevicesMap.carriers[carrierName].total += item.device_count;
      }

      return { carriersDevicesMap, availableDevices, availableCarriers };
    }, [selectedMonthUsageCostData]);

  const sortedAvailableDevices = useMemo(
    () => Array.from(availableDevices).sort(),
    [availableDevices]
  );

  const sortedAvailableCarriers = useMemo(
    () => Array.from(availableCarriers).sort(),
    [availableCarriers]
  );

  const carriersTotal = useMemo(() => {
    return Object.keys(carriersDevicesMap.carriers).reduce((sum, carrier) => {
      return (sum += carriersDevicesMap.carriers[carrier].total);
    }, 0);
  }, [carriersDevicesMap]);

  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Carriers</TableCell>

            {sortedAvailableDevices.map((device) => (
              <TableCell key={device}>{device}</TableCell>
            ))}

            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {sortedAvailableCarriers.map((carrier) => (
            <TableRow key={carrier}>
              <TableCell>{carrier}</TableCell>

              {sortedAvailableDevices.map((device) => (
                <TableCell key={device}>
                  {carriersDevicesMap.carriers[carrier].devices[device] || 0}
                </TableCell>
              ))}

              <TableCell>
                {carriersDevicesMap.carriers[carrier].total}
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell>Total</TableCell>

            {sortedAvailableDevices.map((device) => (
              <TableCell key={device}>
                {selectedMonthUsageCostData
                  .filter((item) => item.formatted_device_type === device)
                  .reduce((sum, item) => (sum += item.device_count), 0)}
              </TableCell>
            ))}

            <TableCell>{carriersTotal}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
