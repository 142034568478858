import {
  UploadProcessTypeNames,
  UploadProcessTypes,
  UploadProcessUI,
} from "@react-ms-apps/common/constants/uploadProcessTypes";

export const dynamicFields: string[] = [
  "company",
  "division",
  "department",
  "acct",
  "project",
  "task",
  "c1",
  "c2",
  "c3",
  "c4",
  "c5",
  "c6",
];

export const uploadProcessUIs: UploadProcessUI[] = [
  {
    processType: UploadProcessTypes.BatchStatementEditor,
    title: UploadProcessTypeNames[UploadProcessTypes.BatchStatementEditor],
    description: [
      "Use this page to perform Batch Statements edits.",
      "Values specified in the non-mandatory columns will be amended to the values in the database; mandatory column cannot be updated.",
      'The file must be a properly-formatted csv file, and the column headings must match the headings listed in the "Upload Process Fields". The order of the headings in the file is not important. Also, the file may include extraneous columns, which will be disregarded. It is not necessary to include all of the columns. Any that are omitted will be ignored.',
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
    ],
    fields: [
      {
        label: "Device",
        required: true,
      },
      {
        label: "Invoice Date",
        required: true,
      },
      {
        label: "Account",
        required: true,
      },
      {
        label: "Equipment",
        required: false,
      },
      {
        label: "Equip Tax $",
        required: false,
      },
      {
        label: "Other",
        required: false,
      },
      {
        label: "Taxes",
        required: false,
      },
      {
        label: "Termination",
        required: false,
      },
      {
        label: "Download",
        required: false,
      },
      {
        label: "GB $",
        required: false,
      },
      {
        label: "Intl GB $",
        required: false,
      },
      {
        label: "Access",
        required: false,
      },
      {
        label: "Feature",
        required: false,
      },
      {
        label: "Data Plan",
        required: false,
      },
    ],
    uploadFormat: "csv",
  },
  {
    processType: UploadProcessTypes.AssetRefresh,
    title: UploadProcessTypeNames[UploadProcessTypes.AssetRefresh],
    description: [
      "Use this page to update subscriber Assets.",
      "The file must be a properly-formatted csv file, and the column headings must match the headings listed below. The order of the headings in the file is not important. Also, the file may include extraneous columns, which will be disregarded. It is not necessary to include all of the columns. Any that are omitted will be ignored. Note, however, that the Device column is required.",
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
      "This refresh is compatible with the Assets export.",
    ],
    fields: [
      {
        label: "Device",
        required: true,
      },
      {
        label: "Radio #",
        required: false,
      },
      {
        label: "Make",
        required: false,
      },
      {
        label: "Model",
        required: false,
      },
      {
        label: "Description",
        required: false,
      },
      {
        label: "Asset ID",
        required: false,
      },
      {
        label: "ESN",
        required: false,
      },
      {
        label: "IMEI",
        required: false,
      },
      {
        label: "PIN",
        required: false,
      },
      {
        label: "SIM ID",
        required: false,
      },
      {
        label: "IP Address",
        required: false,
      },
      {
        label: "Status",
        required: false,
      },
      {
        label: "Plan Activation",
        required: false,
      },
      {
        label: "Upgrade Eligibility",
        required: false,
      },
      {
        label: "Contract Termination",
        required: false,
      },
    ],
    uploadFormat: "csv",
    allowBlankValuesFields: true,
  },
  {
    processType: UploadProcessTypes.UserCreation,
    title: UploadProcessTypeNames[UploadProcessTypes.UserCreation],
    description: [
      "Use this page to add users to the system. The file must be a properly-formatted csv file, and the column headings must match the headings listed below. The order of the headings in the file is not important. Also, the file may include extraneous columns, which will be disregarded. It is not necessary to include all of the columns. Any that are omitted will be ignored.",
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the records were created successfully.",
    ],
    fields: [
      {
        label: "Employee",
        required: true,
        notes: 'In the format: "Last Name, First Name"',
      },
      {
        label: "Login",
        required: true,
      },
      {
        label: "Password",
        required: true,
      },
      {
        label: "Device",
        required: false,
      },
      {
        label: "Employee ID",
        required: false,
      },
      {
        label: "Is Management",
        required: false,
        notes: 'In the format: "Yes" or "No"',
      },
      {
        label: "Email",
        required: false,
      },
    ],
    uploadFormat: "csv",
    hasDynamicFields: true,
  },
  {
    processType: UploadProcessTypes.CostCenterRefreshByDevice,
    title: UploadProcessTypeNames[UploadProcessTypes.CostCenterRefreshByDevice],
    description: [
      "Use this page to update subscriber names and cost centers from a file. The file must be a properly-formatted csv file, and the column headings must match the headings listed below. The order of the headings in the file is not important. Also, the file may include extraneous columns, which will be disregarded. It is not necessary to include all of the columns. Any that are omitted will be ignored. Note, however, that the Device column is required.",
      "Below, there is a checkbox. If the box is checked, then if there are blank values in the input file, those blank values will overwrite existing values. If the box is not checked, then the blank values will be disregarded, leaving the existing value for the corresponding field unchanged.",
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
      "This refresh is compatible with the Admin Charge Detail and Employee exports.",
    ],
    fields: [
      {
        label: "Device",
        required: true,
      },
      {
        label: "Employee",
        required: false,
      },
      {
        label: "Employee ID",
        required: false,
      },
      {
        label: "Email",
        required: false,
      },
      {
        label: "Plan Activation",
        required: false,
      },
      {
        label: "Update Eligibility",
        required: false,
      },
      {
        label: "Contract Termination",
        required: false,
      },
      {
        label: "Contracted Rate Plan",
        required: false,
      },
      {
        label: "Status",
        required: false,
      },
      {
        label: "Job Title",
        required: false,
      },
    ],
    uploadFormat: "csv",
    allowBlankValuesFields: true,
    hasDynamicFields: true,
  },
  {
    processType: UploadProcessTypes.CostCenterRefreshByEmployeeID,
    title:
      UploadProcessTypeNames[UploadProcessTypes.CostCenterRefreshByEmployeeID],
    description: [
      "Use this page to update subscriber information and cost centers from a file. The file must be a properly-formatted csv file, and the column headings must match the headings listed below. The order of the headings in the file is not important. Also, the file may include extraneous columns, which will be disregarded. It is not necessary to include all of the columns. Any that are omitted will be ignored. Note, however, that the Employee ID column is required.",
      "Below, there is a checkbox. If the box is checked, then if there are blank values in the input file, those blank values will overwrite existing values. If the box is not checked, then the blank values will be disregarded, leaving the existing value for the corresponding field unchanged.",
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
      "This refresh is compatible with the Admin Charge Detail and Employee exports.",
    ],
    fields: [
      {
        label: "Employee ID",
        required: true,
      },
      {
        label: "Employee",
        required: false,
      },
      {
        label: "Email",
        required: false,
      },
      {
        label: "Plan Activation",
        required: false,
      },
      {
        label: "Upgrade Eligibility",
        required: false,
      },
      {
        label: "Contract Termination",
        required: false,
      },
      {
        label: "Contracted Rate Plan",
        required: false,
      },
      {
        label: "Status",
        required: false,
      },
      {
        label: "Job Title",
        required: false,
      },
    ],
    uploadFormat: "csv",
    allowBlankValuesFields: true,
    hasDynamicFields: true,
  },
  // {
  //   processType: UploadProcessTypes.MakeManager,
  //   title: UploadProcessTypeNames[UploadProcessTypes.MakeManager],
  //   description: [
  //     "Use this page to update Users to be Managers. The updates file must be a properly-formatted csv file, and the column headings must match the headings listed below. The order of the headings in the file is not important. Also, the file may include extraneous columns, which will be disregarded. It is not necessary to include all of the columns. Any that are omitted will be ignored. Note, however, that the Device column is required.",
  //     "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
  //   ],
  //   fields: [
  //     {
  //       label: "Device",
  //       // http://alpine.mobilsense.com:8180/browse/MOB-3324
  //       required: true,
  //     },
  //   ],
  //   uploadFormat: "csv",
  // },
  {
    processType: UploadProcessTypes.ManagerRefreshByDeviceManager,
    title:
      UploadProcessTypeNames[UploadProcessTypes.ManagerRefreshByDeviceManager],
    description: [
      'Use this page to update the manager for a given device. the Manager name must be in the format "Last Name, First Name"',
      "Below, there is a checkbox. If the box is checked, then if there are blank values in the input file, those blank values will overwrite existing values. If the box is not checked, then the blank values will be disregarded, leaving the existing value for the corresponding field unchanged.",
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
    ],
    fields: [
      {
        label: "Device",
        required: true,
      },
      {
        label: "Manager",
        required: true,
      },
    ],
    uploadFormat: "csv",
    allowBlankValuesFields: true,
  },
  {
    processType: UploadProcessTypes.ManagerRefreshByEmployeeIDManager,
    title:
      UploadProcessTypeNames[
        UploadProcessTypes.ManagerRefreshByEmployeeIDManager
      ],
    description: [
      "Use this page to update the manager for a given Employee ID.",
      "Below, there is a checkbox. If the box is checked, then if there are blank values in the input file, those blank values will overwrite existing values. If the box is not checked, then the blank values will be disregarded, leaving the existing value for the corresponding field unchanged.",
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
    ],
    fields: [
      {
        label: "Employee ID",
        required: true,
      },
      {
        label: "Manager ID",
        required: true,
      },
    ],
    uploadFormat: "csv",
    allowBlankValuesFields: true,
  },
  {
    processType: UploadProcessTypes.PlanOverrideUtility,
    title: UploadProcessTypeNames[UploadProcessTypes.PlanOverrideUtility],
    description: [
      "Use this page to update Statements Plan Codes. The file must be a properly-formatted csv file, and the column headings must match the headings listed below. The order of the headings in the file is not important. Also, the file may include extraneous columns, which will be disregarded.",
      "This process will produce a csv file that provides errors, warnings, and details regarding the data fields that have been updated. Please save the file and review the results to confirm that the refresh was successful.",
    ],
    fields: [
      {
        label: "Master Account",
        required: true,
      },
      {
        label: "Device",
        required: true,
      },
      {
        label: "Plan Code",
        required: true,
      },
      {
        label: "Statement Month",
        required: false,
      },
    ],
    uploadFormat: "csv",
  },
];
