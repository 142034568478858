import { BanSummary } from "@react-ms-apps/common/types";
import axios from "axios";
import { buildApiUrl } from "../utils";

export async function fetchBanSummaries(
  serviceNumber: string
): Promise<BanSummary.BanSummaries> {
  const resp = await axios.get<BanSummary.BanSummaries>(
    buildApiUrl(`api/ban_summaries?service_number=${serviceNumber}`)
  );

  return resp.data;
}

interface StatementSummaryResponse {
  data: BanSummary.BanSummary;
  etag: string;
}

export async function fetchStatementSummary(
  id: number
): Promise<StatementSummaryResponse> {
  const resp = await axios.get(buildApiUrl(`api/ban_summaries/${id}`));

  return {
    data: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function updateStatementSummary(
  id: number,
  fieldName: string,
  fieldValue: any,
  ETAG: string
): Promise<StatementSummaryResponse> {
  // custom logic for DB constraints
  if (
    ["custom_description", "custom_report_description"].includes(fieldName) &&
    fieldValue === ""
  ) {
    fieldValue = null;
  }

  const resp = await axios.put<BanSummary.BanSummary>(
    buildApiUrl(`api/ban_summaries/${id}`),
    {
      [`${fieldName}_delta`]: fieldValue,
      account_statement_id: id,
    },
    {
      headers: {
        "If-Match": ETAG,
      },
    }
  );

  return {
    data: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export type StatementSummaryField = {
  fieldName: BanSummary.EditableField;
  fieldValue: string;
};

export async function updateStatementSummaryFields(
  id: number,
  fields: StatementSummaryField[],
  etag: string
): Promise<StatementSummaryResponse> {
  const payload: {
    [key: string]: string | number | null;
  } = {
    account_statement_id: id,
  };

  for (const { fieldName, fieldValue } of fields) {
    if (
      // custom logic for DB constraints for custom_description and custom_report_description
      ["custom_description", "custom_report_description"].includes(fieldName) &&
      fieldValue === ""
    ) {
      payload[`${fieldName}_delta`] = null;
    } else {
      // otherwise just set the value
      payload[`${fieldName}_delta`] = fieldValue;
    }
  }

  const resp = await axios.put<BanSummary.BanSummary>(
    buildApiUrl(`api/ban_summaries/${id}`),
    {
      ...payload,
      account_statement_id: id,
    },
    {
      headers: {
        "If-Match": etag,
      },
    }
  );

  return {
    data: resp.data,
    etag: resp.headers["etag"] || "",
  };
}

export async function fetchCarrier(id: number): Promise<BanSummary.Carrier> {
  const resp = await axios.get<BanSummary.Carrier>(
    buildApiUrl(`api/restables/carriers/${id}`)
  );

  return resp.data;
}

export async function fetchCarriers(
  ids: number[]
): Promise<BanSummary.Carrier[]> {
  const carriers: BanSummary.Carrier[] = [];

  for (const id of ids) {
    const carrier = await fetchCarrier(id);
    carriers.push(carrier);
  }

  return carriers;
}
